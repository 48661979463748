.Upv_UW_header {
  background-color: var(--color-background);
  z-index: 100;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
  position: sticky;
  top: 0;
}

.Upv_UW_content {
  flex: 1 0 auto;
  padding-block-end: 1rem;
  display: grid;
}

.Upv_UW_tutorial {
  color: var(--color-background-gray);
  text-align: center;
  place-self: center;
  font-size: 2rem;
  font-weight: bold;
}

.Upv_UW_footer {
  background-color: var(--color-background);
  z-index: 10;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: sticky;
  bottom: 0;
}

.Upv_UW_copy {
  color: var(--color-text-dimmed);
  text-align: center;
  padding-block: .5rem;
  font-size: .75rem;
}

.Upv_UW_copy a {
  font-weight: bolder;
}

.CQkswq_form {
  width: 100%;
  max-width: 40rem;
  padding-inline: .5rem;
}

.CQkswq_input {
  flex: 1 0 auto;
}

.CQkswq_popover {
  position: relative;
  overflow: visible;
}

.CQkswq_popover > * {
  z-index: 10;
  width: 100%;
  position: absolute;
  bottom: 100%;
}

.RsMs9W_button {
  background-color: var(--color-text-placeholder);
  box-shadow: var(--outset-shade), var(--outset-light);
  color: #fff;
  cursor: pointer;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  height: 3rem;
  padding-inline: 1rem;
  line-height: 1.25;
  display: inline-flex;
}

.RsMs9W_button:not(:disabled):active {
  box-shadow: var(--inset);
  translate: .125rem .125rem;
}

.RsMs9W_button.RsMs9W_blue {
  background-color: var(--color-blue);
}

.RsMs9W_button.RsMs9W_red {
  background-color: var(--color-red);
}

.RsMs9W_button.RsMs9W_green {
  background-color: var(--color-green);
}

.RsMs9W_button.RsMs9W_yellow {
  background-color: var(--color-yellow);
}

.RsMs9W_button:disabled {
  background-color: var(--color-background-gray);
}

.RsMs9W_button:focus-visible {
  outline: .125rem solid var(--color-text);
}

._8Xu39W_options {
  box-shadow: var(--outset-shade);
  overflow: scroll;
  overflow: overlay;
  overscroll-behavior: none;
  border-radius: .25rem;
  width: 100%;
  max-height: min(60dvh, 16.5rem);
  margin-block-end: .25rem;
}

._8Xu39W_options:focus-visible {
  outline: .125rem solid var(--color-text);
}

._8Xu39W_options li {
  background-color: var(--color-background-bright);
  align-items: center;
  height: 3rem;
  padding-inline: 1rem;
  display: flex;
}

._8Xu39W_options li[role="option"] {
  cursor: pointer;
}

._8Xu39W_options li._8Xu39W_candidate {
  background-color: var(--color-background-gray);
}

._8Xu39W_options li:first-child {
  border-radius: .25rem .25rem 0 0;
}

._8Xu39W_options li:last-child {
  border-radius: 0 0 .25rem .25rem;
}

._8Xu39W_options li span {
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
}

.LDSrya_input {
  background-color: var(--color-text);
  box-shadow: var(--inset);
  color: var(--color-background);
  cursor: text;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  height: 3rem;
  padding-inline: .75rem;
  display: inline-flex;
}

.LDSrya_input::-webkit-search-cancel-button {
  background-blend-mode: overlay;
  cursor: pointer;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' fill='%23242528'><path d='M49.6648 5.87522C55.0924 0.13696 63.8684 8.89823 58.1226 14.3331L40.5928 31.8632C40.5 31.9558 40.5 32.1235 40.5928 32.2162L58.1226 49.7462C63.6488 55.182 55.1077 63.7412 49.6628 58.2081L32.1331 40.678C32.0405 40.5852 31.8727 40.5852 31.7801 40.678L14.2504 58.2081C8.80677 63.7425 0.263624 55.1853 5.78862 49.7482L23.3184 32.2181C23.4094 32.1274 23.4094 31.9559 23.3184 31.8652L5.78862 14.3351C0.429546 8.88923 8.80139 0.51542 14.2484 5.87323C21.0942 12.7191 24.9323 16.5574 31.7781 23.4033C31.8707 23.4961 32.0385 23.4961 32.1311 23.4033C38.9784 16.5581 42.8175 12.7204 49.6648 5.87522Z' /></svg>") center / 1rem no-repeat;
  width: 1em;
  height: 1em;
  font-size: 1.5rem;
  display: inline-block;
}

.qvtmNG_flex {
  display: flex;
}

.eXhapG_score {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 40rem;
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
}

.eXhapG_score figure {
  gap: .25rem;
  display: flex;
}

.vtsT4W_icon {
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1em;
  display: inline-block;
}

.QCqwhG_toast {
  background-color: var(--color-background);
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1.5rem 1rem;
  display: flex;
}

.QCqwhG_positive {
  background-color: var(--color-background-green);
}

.QCqwhG_negative {
  background-color: var(--color-background-red);
}

.QCqwhG_toast div {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.QCqwhG_toast h1 {
  text-shadow: var(--outset-shade);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
}

.VR9CUW_history {
  flex-direction: column;
  place-self: start center;
  gap: 1.5rem;
  width: 100%;
  max-width: 40rem;
  height: 100%;
  padding-inline: 1rem;
  display: flex;
}

.VR9CUW_item {
  align-items: start;
  gap: .375rem;
  display: flex;
}

.VR9CUW_item > * {
  flex: 0 100%;
}

.VR9CUW_attempt {
  background-color: var(--color-background-bright);
  color: var(--color-background);
  text-align: center;
  border-radius: .75em;
  flex: 0 0 1.5em;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5em;
}

.pM7R7a_guess {
  flex-direction: column;
  gap: .125rem;
  display: flex;
}

.pM7R7a_guess h1 {
  text-shadow: var(--outset-shade);
  padding-inline: .375rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
}

.pM7R7a_guess ul {
  flex-direction: column;
  display: flex;
}

._4I4FWq_hint {
  border-radius: .25rem;
  align-items: start;
  gap: .5rem;
  padding: .125rem .375rem;
  display: flex;
  position: relative;
}

._4I4FWq_hint:hover, ._4I4FWq_hint:focus-visible, ._4I4FWq_hint:focus-within {
  background-color: var(--color-background-bright);
}

._4I4FWq_hint._4I4FWq_exact {
  color: var(--color-green);
}

._4I4FWq_hint._4I4FWq_partial {
  color: var(--color-yellow);
}

._4I4FWq_hint._4I4FWq_lower, ._4I4FWq_hint._4I4FWq_higher, ._4I4FWq_hint._4I4FWq_mismatch {
  color: var(--color-red);
}

._4I4FWq_icon {
  flex: none;
  margin-block-start: .3rem;
  font-size: 1rem;
}

.L5575a_tooltip {
  pointer-events: none;
  z-index: 10;
  max-width: 70%;
}

.L5575a_tooltip:not(.L5575a_active) {
  display: none;
}

.L5575a_green .L5575a_content {
  background-color: var(--color-background-green);
}

.L5575a_yellow .L5575a_content {
  background-color: var(--color-background-yellow);
}

.L5575a_red .L5575a_content {
  background-color: var(--color-background-red);
}

.L5575a_pointer {
  color: var(--color-background-bright);
  width: 1em;
  height: 1em;
  font-size: .75rem;
  display: block;
}

.L5575a_green .L5575a_pointer {
  color: var(--color-background-green);
}

.L5575a_yellow .L5575a_pointer {
  color: var(--color-background-yellow);
}

.L5575a_red .L5575a_pointer {
  color: var(--color-background-red);
}

.L5575a_content {
  background-color: var(--color-background-bright);
  box-shadow: var(--outset-shade);
  color: #ffffffbf;
  border-radius: 0 .25rem .25rem;
  padding: .5rem .75rem;
}

.L5575a_content strong, .L5575a_content a:hover, .L5575a_content a:focus-visible {
  color: #fff;
}
/*# sourceMappingURL=index.a59de102.css.map */
